import Vue from 'vue'

export default {
  state: {
    loader: false,
    dashboardAnalytics: {},
    allLoanLevels: [],
    customer: [],
    allLoanRequests: [],
    allUpcomingRepayments: [],
    upcomingRepaymentsTotal: 0,
    customerTotal: 0,
    allLoansTotal: 0,
    loanRequestsTotal: 0,
    currentPage: 1,
    customerCurrentPage: 1,
    loanRequestCurrentPage: 1,
    perPage: 5,
    searchQuery: '',
    approval_status: '',
    payment_status: '',
    repayment_plan: '',
    loanHistory: [],
    numberOfLoans: ''
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    getDashboardAnalytics({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get('admin/dashboard/admin')
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'dashboardAnalytics',
              with: response.data.data
            })
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          })
      })
    },
    getAllLoansLevels({ commit, state }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        const params = {
          page: state.currentPage,
          per_page: state.perPage,
          ...(state.searchQuery && { search_query: state.searchQuery })
        }
        Vue.$http.get('admin/loans/settings/loan-levels', { params })
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'allLoanLevels',
              with: response.data.data.results
            })
            commit('mutate', {
              property: 'allLoansTotal',
              with: response.data.data.total
            })
            resolve(response)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    createLoanLevel({ commit }, payload) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post('admin/loans/settings/loan-levels', payload)
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(response)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    handleVerifyUser({ commit }, id) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.patch(`/admin/customer/${id}/verify`)
          .then(response => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(response)
          })
          .catch(err => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    getAllCustomers({ commit, state }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        const params = {
          page: state.customerCurrentPage,
          per_page: state.perPage,
          ...(state.searchQuery && { search_query: state.searchQuery })
        }
        Vue.$http.get('/admin/customer', { params })
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'customers',
              with: response.data.data.results
            })
            commit('mutate', {
              property: 'customersTotal',
              with: response.data.data.total
            })
            resolve(response)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    approveLoanRequest({ commit }, id) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.put(`/admin/loans/requests/${id}/approve`)
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(response)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    viewOneUser({ commit }, id) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/admin/customer/${id}`)
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    updateCustomer({ commit }, paylaod) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.put(`/admin/customer/${paylaod.id}`, paylaod)
          .then((response) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(response)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    getAllLoanRequests({ commit, state }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        const filterBy = {
          ...(state.approval_status && { approval_status: state.approval_status }),
          ...(state.payment_status && { payment_status: state.payment_status }),
          ...(state.repayment_plan && { repayment_plan: state.repayment_plan })
        }
        const params = {
          page: state.loanRequestCurrentPage,
          per_page: state.perPage,
          ...(Object.keys(filterBy).length > 0 && { filter_by: JSON.stringify(filterBy) }),
          ...(state.searchQuery && { search_query: state.searchQuery })
        }
        Vue.$http.get('/admin/loans/requests', { params: params })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'allLoanRequests',
              with: res.data.data.results
            })
            commit('mutate', {
              property: 'loanRequestsTotal',
              with: res.data.data.total
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    },
    getAllUpcomingRepayments({ commit, state }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        const filterBy = {
          ...(state.repayment_plan && { repayment_plan: state.repayment_plan })
        }
        const params = {
          page: state.currentPage,
          per_page: state.perPage,
          ...(Object.keys(filterBy).length > 0 && { filter_by: JSON.stringify(filterBy) }),
          ...(state.searchQuery && { search_query: state.searchQuery })
        }
        Vue.$http.get('/admin/loans/repayments', { params: params })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'allUpcomingRepayments',
              with: res.data.data.results
            })
            commit('mutate', {
              property: 'upcomingRepaymentsTotal',
              with: res.data.data.total
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    },
    handleSizeChange({ commit, dispatch }, perPage) {
      commit('mutate', {
        property: 'perPage',
        with: perPage
      })
      commit('mutate', {
        property: 'currentPage',
        with: 1
      })
      dispatch('getAllLoansLevels')
    },
    handleCurrentChange({ commit, dispatch }, page) {
      commit('mutate', {
        property: 'currentPage',
        with: page
      })
      dispatch('getAllLoansLevels')
    },
    customerHandleSizeChange({ commit, dispatch }, perPage) {
      commit('mutate', {
        property: 'perPage',
        with: perPage
      })
      commit('mutate', {
        property: 'customerCurrentPage',
        with: 1
      })
      dispatch('getAllCustomers')
    },
    customerHandleCurrentChange({ commit, dispatch }, page) {
      commit('mutate', {
        property: 'customerCurrentPage',
        with: page
      })
      dispatch('getAllCustomers')
    },
    loanRequestHandleSizeChange({ commit, dispatch }, perPage) {
      commit('mutate', {
        property: 'perPage',
        with: perPage
      })
      commit('mutate', {
        property: 'loanRequestCurrentPage',
        with: 1
      })
      dispatch('getAllLoanRequests')
    },
    loanRequestHandleCurrentChange({ commit, dispatch }, page) {
      commit('mutate', {
        property: 'loanRequestCurrentPage',
        with: page
      })
      dispatch('getAllLoanRequests')
    },
    upcomingRepaymentsHandleSizeChange({ commit, dispatch }, perPage) {
      commit('mutate', {
        property: 'perPage',
        with: perPage
      })
      commit('mutate', {
        property: 'currentPage',
        with: 1
      })
      dispatch('getAllUpcomingRepayments')
    },
    upcomingRepaymentsHandleCurrentChange({ commit, dispatch }, page) {
      commit('mutate', {
        property: 'currentPage',
        with: page
      })
      dispatch('getAllUpcomingRepayments')
    }
  },
}